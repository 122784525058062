.mapContainer {
  position: relative;
  width: 100%;
  padding-top: 67%;

  @media all and (max-width: 767px) {
    padding-top: 100%;
  }
}

.map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
